// Landing.js
import React from "react";
// import Template from "./Template";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import Template from "./template";

const Landing = () => {
  const navigate = useNavigate();
  const options = [
    { id: 1, name: "1 цаг 1000₮", hour: 1, price: 1000, profile: "1 Hour" },
    { id: 2, name: "3 цаг 2500₮", hour: 3, price: 2500, profile: "3 Hour" },
    { id: 3, name: "7 цаг 5000₮", hour: 7, price: 5000, profile: "7 Hour" },
    { id: 4, name: "12 цаг 8000₮", hour: 12, price: 8000, profile: "12 Hour" },
  ];

  const handleClick = (item) => {
    navigate(`/order/${item}`);
  };

  const backHome = () => {
    window.open("https://sln.mn", "_blank");
  };

  return (
    <Template>
      <div className="o-title">Үйлчилгээний багц</div>
      <div className="background-container">
        <div className="card-container">
          {options.map((item, index) => (
            <div
              className="card"
              key={index}
              onClick={() => handleClick(item.id)}
            >
              <span className="card-time">{item.hour} цаг</span>
              <span className="card-price">{item.price} ₮</span>
              <span className="card-text">дата хязгааргүй</span>
            </div>
          ))}
        </div>
        <div className="session-button" style={{ padding: 5 }}>
          <Button className="check-button" onClick={backHome}>
            BACK
          </Button>
          <Button
            className="check-button"
            onClick={() => window.open("/WebStarlinkNomad.pdf")}
          >
            ЗААВАР
          </Button>
        </div>
      </div>
    </Template>
  );
};

export default Landing;
