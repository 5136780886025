import React, { useEffect, useState } from "react";
import webVideo from "./assets/star_desktop.mp4";
import mobileVideo from "./assets/star_mobile.mp4";
import logo from "./assets/logo.png";
import instruction from "./assets/instruction.gif";

const Template = ({ children }) => {
  const [videoSource, setVideoSource] = useState(null);

  useEffect(() => {
    const detectScreenWidth = () => {
      const isMobile = window.innerWidth <= 768;
      setVideoSource(isMobile ? mobileVideo : webVideo);
    };

    detectScreenWidth();
    window.addEventListener("resize", detectScreenWidth);

    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);

  return (
    <div className="template">
      <div className="overlay"></div>
      <video
        id="video"
        autoPlay
        muted
        loop
        playsInline
        src={videoSource}
      ></video>
      
      {/* Header Section */}
      <div className="header-section">
        <img src={logo} alt="logo" className="landing-logo" />
        <div className="hr-sl"></div>
        <p className="landing-text">явдаг интернэт</p>
      </div>

      {/* Body Section */}
      <div className="body-section">
        {children}
      </div>

      {/* Footer Section */}
      <div className="footer-section">
        <div className="img-cont2">
          <div className="link">
            <img src={instruction} style={{ height: 30, width: 30 }} alt="Logo" />
          </div>
        </div>
        <span className="c-text">
          <p className="link-text" onClick={() => window.open("/WebStarlinkNomad.pdf")}>
            Заавар
          </p>
        </span>
      </div>
    </div>
  );
};

export default Template;
