import React from "react";
import "./App.css";
import Landing from "./Landing";

const Home = () => {
  return (
    <>
      <div className="background-container">
        <div id="landing" className="section-main" style={{ padding: 0 }}>
          <Landing />
        </div>
      </div>
    </>
  );
};

export default Home;
