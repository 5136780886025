import React, { useEffect, useState } from "react";
import { Button, Modal, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import copy from "../assets/icon/copy.png";
import notif from "../assets/icon/notif.png";
import Template from "../template";

const Info = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const arrayData = location.state?.data;
  const qrImage = location.state?.ebarimt;
  const ebarimt_lottery = location.state?.ebarimt_lottery;
  const amount = location.state?.amount;
  const [username] = useState(arrayData);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  useEffect(() => {
    const handlePopState = () => {
      navigate("/", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    setOpenModalInfo(true);
  }, []);

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: "Амжилттай хууллаа.",
      duration: 3,
    });
  };

  const login = () => {
    window.open("https://sln.mn", "_blank");
  };

  const handleOk = () => {
    setOpenModalInfo(false);
  };

  return (
    <Template>
      <div className="o-title" >Дараах нэвтрэх кодыг хадгалж авна уу</div>
      <div className="o-container">
        <div className="ebarimt">
          <div className="info">
            <div className="code">
              <strong onClick={() => copyText(username)}>{username}</strong>
            </div>
            <div className="copy">
              <img
                src={copy}
                alt="ds"
                style={{
                  height: "100%",
                  width: "100%",
                  marginLeft: 20,
                }}
                onClick={() => copyText(username)}
              ></img>
            </div>
          </div>

          <div className="hr-sl"></div>
          <div style={{ height: 60, backgroundColor: "white", borderRadius: 12, marginTop: 20 }}>
            <img
              src="https://sugalaa.ebarimt.mn/static/media/eBarimt_logo.c1904317f24943bd3c71.png"
              alt="QR code"
              style={{
                height: "100%",
                width: "100%",
              }}
            ></img>
          </div>
          <div className="card-title">Сугалааны дугаар: <span className="font-weight-600">{ebarimt_lottery}</span></div>
          <div className="card-title">Бүртгүүлэх дүн:<span className="font-weight-600"> {amount} ₮</span></div>
          <div className="ebarimt-img">
            <img
              src={qrImage}
              alt="QR code"
              style={{
                height: "100%",
                width: "100%",
              }}
            ></img>
          </div>

        </div>

        <Modal
          open={openModalInfo}
          onOk={handleOk}
          onCancel={handleOk}
          cancelButtonProps={{ style: { display: "none" } }}
          width={360}
          okButtonProps={{ style: { backgroundColor: "black" } }}
          closable={false}
        >
          <div style={{
            display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
          }}>
            <span style={{ fontWeight: 500, fontSize: 20, padding: 40 }}>Анхааруулга</span>
            <div style={{
              height: 70, width: 70
            }}>
              <img src={notif} style={{ height: "100%", width: "100%" }}></img>
            </div>
            <div style={{
              display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
            }}>
              <span style={{ textAlign: "center", marginTop: 20 }}>Нэвтрэх код болон EBarimt-н мэдээллийг имэйл-ээр илгээсэн болно.</span>          </div>
          </div>
        </Modal>
      </div>
      <div className="login-container">
        <Button className="check-button" onClick={login}>
          Connect
        </Button>
      </div>
    </Template>

  );
};

export default Info;
