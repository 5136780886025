import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Button, Modal } from "antd";
import Template from "../template";
import loading from "../assets/loading.gif";

const options = [
  { id: 1, name: "1 цаг 1000₮", hour: 1, price: 1000, profile: "1 Hour" },
  { id: 2, name: "3 цаг 2500₮", hour: 3, price: 2500, profile: "3 Hour" },
  { id: 3, name: "7 цаг 5000₮", hour: 7, price: 5000, profile: "7 Hour" },
  { id: 4, name: "12 цаг 8000₮", hour: 12, price: 8000, profile: "12 Hour" },
];

const bank = [
  "State bank 3.0",
  "Trade and Development bank",
  "Social Pay",
  "Xac bank",
  "Capitron bank",
  "Khan bank",
  "Monpay",
  "M bank",
  "Arig bank"
];
const User = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const itemId = Number(id);
  const arrayData = location.state?.arrayData;
  const qrImage1 = location.state?.qrImage;
  const invoice_id = location.state?.invoiceId;
  const orderEmail = location.state?.email;
  const username = location.state?.username;
  const [email] = useState(orderEmail);
  const [qrImage] = useState(qrImage1);
  const [invoiceId] = useState(invoice_id);
  const [urls] = useState(arrayData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const filteredItem = options.find((item) => item.id === itemId);
  const [bankList, setBankList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (urls) {
      const filteredBanks = urls.filter((item) => bank.includes(item.name));
      const orderedBanks = filteredBanks.sort(
        (a, b) => bank.indexOf(a.name) - bank.indexOf(b.name)
      );
      setBankList(orderedBanks);
    }
  }, [urls]);

  useEffect(() => {
    if (invoiceId) {
      const interval = setInterval(onCheck, 5000);
      return () => clearInterval(interval);
    }
  }, [invoiceId]);

  const onCheck = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/check`,
        { invoiceId, email, name:username }
      );
      const qrEbarimt = res.data.qrCodeBase64;
      const ebarimt_lottery = res.data.ebarimt_lottery;
      const amount = res.data.amount;

      if (res.data && res.data.count !== 0) {
        clearInterval(onCheck);
        setIsLoading(false);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_API_URL}/api/user`,
            {
              name: username,
              profile: filteredItem.profile,
              email: email,
              invoice_id: invoiceId,
              qrEbarimt: qrEbarimt,
              ebarimt_lottery: ebarimt_lottery,
              amount: amount
            }
          );
          if (response.data&&response.data.id) {

            navigate("/info", { state: { data: response.data.username, ebarimt: qrEbarimt, ebarimt_lottery: ebarimt_lottery, amount: amount } });
            const responseEmail = await axios.post(
              `${process.env.REACT_APP_BACKEND_API_URL}/api/email`,
              {
                username: response.data.username, ebarimt: qrEbarimt, ebarimt_lottery: ebarimt_lottery, amount: amount, email: email
              }
            );
            console.log("Response email: ", responseEmail)
          } else if (response.status === 500) {
            setIsLoading(true);
          }
        } catch (error) {
          console.error("Error creating user:", error);
        }
      }
      // }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleAppRedirect = (url) => {
    const timeout = 2000;
    let hasOpenedApp = false;

    const timer = setTimeout(() => {
      if (!hasOpenedApp) {
        showModal();
      }
    }, timeout);

    window.location.href = url;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        hasOpenedApp = true;
        clearTimeout(timer);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  };

  return (
    <Template>
      <div className="o-title">Төлбөр төлөх</div>
      <div className="user-container">

        <div className="qr-container">
          <img
            src={`data:image/png;base64,${qrImage}`}
            alt="QR Code"
            style={{ width: "100%", maxWidth: "400px" }}
          />
        </div>
        <div className="user-grid">
          {bankList.map((url, index) => (
            <div key={index} style={{ textAlign: "center" }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleAppRedirect(url.link);
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={url.logo} alt={url.name} className="grid-logo" />
              </a>
              <p className="grid-desc">
                {url.description.length > 16
                  ? `${url.description.slice(0, 16)}...`
                  : url.description}
              </p>
            </div>
          ))}
        </div>
        {isLoading && (
          <div >
            <img src={loading} alt="loading" style={{ height: 30, width: 30, marginTop: -20, marginBottom: -10 }}></img>
          </div>
        )}
        <Modal
          title="Анхааруулга"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleOk}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              ОК
            </Button>,
          ]}
        >
          <p>Төхөөрөмжийн тохиргооноос хамаарч банкны мобайл аппликэшнийг нээх боломжгүй байгаа тул доорх холбоосыг веб хөтөч дээр дуудаж ашиглана уу.</p>
          <div style={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "flex-end" }}>
            <span style={{ color: "black" }} >https://portal.starlinknomad.mn</span>
            <Button
              style={{ marginLeft: 10, border: "1px solid gray", color: "black" }}
              type="link"
              onClick={() => {
                navigator.clipboard.writeText("https://portal.starlinknomad.mn");
              }}
            >
              хуулах
            </Button>
          </div>
        </Modal>
      </div>
    </Template>
  );
};

export default User;